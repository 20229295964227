const SET_AAA = 'SET_AAA'

const app = {
	namespaced: true,
	state: {
		aaa: 111,
	},
	mutations: {
		[SET_AAA](state, data) {
			state.aaa = data
		},
	},
	actions: {
		setAaa({ commit }, data) {
			commit(SET_AAA, data)
		},
	},
	getters: {
		getAaa: (state) => state.aaa,
	},
}

export default app

// 在组件中使用 getter
// 你可以通过 mapGetters 辅助函数将 getter 映射到组件的计算属性中，或者直接通过 $store.getters 访问。
/*
<template>
  <div>
    <p>ABC: {{ abc }}</p>
    <p>AAA: {{ aaa }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    // 使用 mapGetters 辅助函数
   ...mapGetters({
      abc: 'getMessage', // 映射主 store 的 getter
    }),
    ...mapGetters('app', {
      aaa: 'getAaa', // 映射 app 模块的 getter
    }),
    // 或者直接使用
    // abc() {
    //   return this.$store.getters.getValue;
    // },
    // aaa() {
    //   return this.$store.getters['app/getAaa'];
    // }
  },
  mounted(){
    this.$store.commit('app/SET_AAA', 123); // 直接调用mutation
    this.$store.dispatch('app/setAaa', 123); //通过action来调用
    console.log(this.$store.state.app.aaa);
  }
};
</script>
*/
