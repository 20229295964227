import createApp from './src/app.js'

const { app, router, store } = createApp()

// 检查是否存在服务器端注入的状态 在客户端对store进行替换。
if (window.__INITIAL_STATE__) {
	store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
	updateMeta(router.currentRoute)
	router.beforeEach((to, from, next) => {
		updateMeta(to)
		next()
	})

	app.$mount('#app')
})

function updateMeta(route) {
	const meta = route.meta
	// 游戏详情meta在页面设
	if (JSON.stringify(meta) == "{}") {
		return
	}
	document.title = meta.title || 'yolo games'

	setMetaTag('description', meta.description || 'Default Description')
	setMetaTag('og:description', meta.description || 'Default Description')
	setMetaTag('og:title', meta.title || 'yolo games')
	setMetaTag('title', meta.title || 'yolo games')
}

function setMetaTag(name, content) {
	let element = document.querySelector(`meta[name="${name}"]`)
	if (element) {
		element.setAttribute('content', content)
	} else {
		element = document.createElement('meta')
		element.setAttribute('name', name)
		element.setAttribute('content', content)
		document.head.appendChild(element)
	}
}

// // 客户端数据预取 参考: https://blog.csdn.net/qq_42880714/article/details/125966481
// // 在客户端，处理数据预取有两种不同方式：

// // 在路由导航之前解析数据
// // 使用此策略，应用程序会等待视图所需数据全部解析之后，再传入数据并处理当前视图。好处在于，可以直接在数据准备就绪时，传入视图渲染完整内容，但是如果数据预取需要很长时间，用户在当前视图会感受到"明显卡顿"。因此，如果使用此策略，建议提供一个数据加载指示器 (data loading indicator)。
// // 我们可以通过检查匹配的组件，并在全局路由钩子函数中执行 asyncData 函数，来在客户端实现此策略。注意，在初始路由准备就绪之后，我们应该注册此钩子，这样我们就不必再次获取服务器提取的数据。

// // 添加路由钩子函数，用于处理 asyncData.
// // 在初始路由 resolve 后执行，
// // 以便我们不会二次预取(double-fetch)已有的数据。
// // 使用 `router.beforeResolve()`，以便确保所有异步组件都 resolve。
// router.beforeResolve((to, from, next) => {
// 	console.log('aaa')
// 	const matched = router.getMatchedComponents(to)
// 	const prevMatched = router.getMatchedComponents(from)
// 	console.log(matched)
// 	console.log(prevMatched)
// 	// 我们只关心非预渲染的组件
// 	// 所以我们对比它们，找出两个匹配列表的差异组件
// 	let diffed = false
// 	const activated = matched.filter((c, i) => {
// 		return diffed || (diffed = prevMatched[i] !== c)
// 	})
// 	if (!activated.length) {
// 		return next()
// 	}
// 	// 这里如果有加载指示器 (loading indicator)，就触发
// 	Promise.all(
// 		activated.map((c) => {
// 			if (c.asyncData) {
// 				return c.asyncData({ store, route: to })
// 			}
// 		}),
// 	)
// 		.then(() => {
// 			// 停止加载指示器(loading indicator)

// 			next()
// 		})
// 		.catch(next)
// })
