<template>
	<div class="fa" >
		<div @click="load"
			v-show="ifShowLoad"
			class="load">
			{{  text }}
		</div>
		<div v-show="!ifShowLoad">
			<el-pagination
				class="page"
				background
				@current-change="currentChange"
				layout="prev,pager,next"
				:page-size="pageSize"
				:current-page="currentPage"
				:total="total" />
		</div>
	</div>
</template>

<script>
export default {
	props: ['currentPage', 'total', 'ifAllLoad', 'pageSize'],
	mounted() {
		if (!this.ifShowLoad) {
			setTimeout(() => {
				document.querySelector('.el-icon-arrow-left')?.remove()
				document.querySelector('.el-icon-arrow-right')?.remove()
			})
			setTimeout(() => {
				document.querySelector('.btn-prev').innerText = 'Prev'
				document.querySelector('.btn-next').innerText = 'Next'
			})
		}
	},
	data() {
		return {}
	},
	computed: {
		ifShowLoad() {
			return this.$store.state.deviceType === 'mobile' || this.$route.path.includes('click-games')
		},
		text () {
			return this.ifAllLoad ? 'No More Games' : 'Load More Games'
		}
	},
	methods: {
		load () {
			if (!this.ifAllLoad)
			this.$emit('load');
		},
		currentChange(curIndex) {
			this.$emit('pageChange', curIndex)
			
		},
	},
}
</script>

<style lang="scss" scoped>
.load {
	width: rem(300);
	height: rem(110);
	line-height: rem(110);
	text-align: center;
	border-radius: rem(100);
	color: #fff;
	background: #28abfa;
	margin: rem(50) auto;
	font-size: rem(26);
	font-family: Roboto;
	font-weight: 600;
}
.fa {
	display: flex;
	justify-content: center;
}
.page {
	margin: rem(70) 0 rem(60);
}
.page::before {
	vertical-align: top;
	content: 'Page:';
	display: inline-block;
	font-size: 17px;
	margin-right: 30px;
	/* line-height: 27px; */
	color: #0e2a4d;
}
</style>