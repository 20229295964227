<template>
  <div class="bottomCate">
    <a v-for="item in cates" :key="item" class="item"
      :href="`/${item.toLocaleLowerCase().replaceAll(' ', '-')}`">
      <img loading="lazy" decoding="async" :src="require(`@/assets/${item.toLocaleLowerCase().replaceAll(' ', '-')}.png`)">
      <div class="text">{{ item }}</div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Bottom',
  data () {
    return {
      cates: ['Action Games', 'Casual Games', 'Puzzle Games', 'Racing Games', 'Sport Games', 'Match 3 Games', 'Adventure Games', 'Shooting Games', 'Strategy Games', 'Number Games'],
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.item {
	overflow: hidden;
	background-color: white;
	height: rem(160);
	display: flex;
	border-radius: rem(20);
	box-shadow: rem(0) rem(5) rem(15) rem(0) rgba(0, 0, 0, 0.4);
	img {
		flex: 1;
		height: 100%;
	}
	.text {
		padding: rem(10);
		display: flex;
		flex: 1;
		color: #002b51;
		font-family: Roboto;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		text-align: center;
		align-items: center;
	}
}
.bottomCate {
	img {
		object-fit: cover;
	}
}
@media screen and (orientation: portrait) {
	.bottomCate {
		grid-template-columns: repeat(2, minmax(0, 1fr));
		display: grid;
		gap: 0.4rem;
	}
}

// 横屏pc
@media screen and (orientation: landscape) {
	.bottomCate {
		display: grid;
		grid-template-columns: repeat(8, 94px);
		justify-content: center;
		gap: 0.4rem;

		.item {
			grid-column: span 2 / span 2;
		}
	}
}
@media (min-width: 1022px) {
	.bottomCate {
		grid-template-columns: repeat(10, 94px);
	}
}
</style>