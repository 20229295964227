import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
import Meta from 'vue-meta'
import global from '@/utils/global'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
import '@/icons/index.js'
import '@/components'
import { Pagination } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



Vue.use(Meta)
Vue.use(Pagination)


function initializeFirebase() {
	const firebaseConfig = window.__INITIAL_STATE__ ? window.__INITIAL_STATE__.firebaseConfig : global.firebaseConfig
	let app = initializeApp(firebaseConfig)
	let analytics = getAnalytics(app)
	return analytics
}

function setupAnalytics() {
	// // 此代码块只在客户端执行
	isSupported().then((result) => {
		if (result) {
			const analytics = initializeFirebase()
			logEvent(analytics, 'in_page')
			console.log('in_page')
			// app.js 中的全局日志功能
			Vue.prototype.$logEvent = (event, params = {}) => {
				console.log(event)
				logEvent(analytics, event, params)
			}
		} else {
			// 如果 Firebase Analytics 不支持
			console.log('Firebase Analytics not support')
			Vue.prototype.$logEvent = (event, params = {}) => {
				console.log(`Analytics not supported: ${event}`, params)
			}
		}
	})
}

if (typeof window !== 'undefined') {
	setupAnalytics()
} else {
	// 对于服务器端，你可以定义一个简单的函数或者不做任何事情
	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
}

// simple event
export default function createApp() {
	const router = createRouter()
	const store = createStore()
	const app = new Vue({
		router,
		store,
		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
