<template>
	<div class="header">
		<div
			v-show="showMenu"
			class="cover" />

		<div
			class="banner"
			:class="showMenu ? 'menu' : ''">
			<div class="top">
				<a	
					
					href="/"
					class="logo"></a>
				<div class="flex">
					<svg
						v-show="!showMenu"
						@click="showMenu = !showMenu"
						viewBox="0 0 45 45"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<rect
							width="45"
							height="45"
							fill="white"
							fill-opacity="0.01" />
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M7.03125 11.8333C7.03125 10.9128 7.77744 10.1667 8.69792 10.1667H35.3646C36.2851 10.1667 37.0312 10.9128 37.0312 11.8333C37.0312 12.7538 36.2851 13.5 35.3646 13.5H8.69792C7.77744 13.5 7.03125 12.7538 7.03125 11.8333ZM7.03125 34.3333C7.03125 33.4129 7.77744 32.6667 8.69792 32.6667H35.3646C36.2851 32.6667 37.0312 33.4129 37.0312 34.3333C37.0312 35.2538 36.2851 36 35.3646 36H8.69792C7.77744 36 7.03125 35.2538 7.03125 34.3333ZM8.66667 21.6667C7.74619 21.6667 7 22.4129 7 23.3333C7 24.2538 7.74619 25 8.66667 25H35.3333C36.2538 25 37 24.2538 37 23.3333C37 22.4129 36.2538 21.6667 35.3333 21.6667H8.66667Z"
							fill="black" />
					</svg>
					<svg
						v-show="showMenu"
						@click="showMenu = !showMenu"
						width="45"
						height="45"
						viewBox="0 0 45 45"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<rect
							width="45"
							height="45"
							fill="white"
							fill-opacity="0.01" />
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M7.03125 11.8333C7.03125 10.9128 7.77744 10.1667 8.69792 10.1667H35.3646C36.2851 10.1667 37.0312 10.9128 37.0312 11.8333C37.0312 12.7538 36.2851 13.5 35.3646 13.5H8.69792C7.77744 13.5 7.03125 12.7538 7.03125 11.8333ZM7.03125 23.0833C7.03125 22.1628 7.77744 21.4167 8.69792 21.4167H24.8646C25.7851 21.4167 26.5312 22.1628 26.5312 23.0833C26.5312 24.0038 25.7851 24.75 24.8646 24.75H8.69792C7.77744 24.75 7.03125 24.0038 7.03125 23.0833ZM8.69792 32.6667C7.77744 32.6667 7.03125 33.4128 7.03125 34.3333C7.03125 35.2538 7.77744 36 8.69792 36H22.3646C23.2851 36 24.0312 35.2538 24.0312 34.3333C24.0312 33.4128 23.2851 32.6667 22.3646 32.6667H8.69792Z"
							fill="black" />
						<path
							d="M37.6364 25.8462V40.3636C37.6364 41.2674 36.9037 42 36 42C35.0963 42 34.3636 41.2674 34.3636 40.3636V25.8462L30.3658 29.6048C29.7617 30.1727 28.8201 30.1727 28.216 29.6048C27.5569 28.9851 27.5569 27.9379 28.216 27.3183L35.7105 20.2721C35.8732 20.1192 36.1268 20.1192 36.2895 20.2721L43.784 27.3183C44.4431 27.9379 44.4431 28.9851 43.784 29.6048C43.1799 30.1727 42.2383 30.1727 41.6342 29.6048L37.6364 25.8462Z"
							fill="#111111" />
					</svg>
				</div>
			</div>

			<!-- 点击展开的菜单 -->
			<div
				class="cate"
				v-show="showMenu">
				<div
					:key="item.name"
					v-for="item in gameCate"
					class="gameCate">
					<svg-icon :icon-class="item.name.toLocaleLowerCase().replaceAll(' ', '-')" />
					<a :href="`/${item.name.toLocaleLowerCase().replaceAll(' ', '-')}`">{{ item.name }}</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
''.toLocaleLowerCase().replaceAll(' ', '-')
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			imgUrl: '',
			showMenu: false,
			gameCate: [
				{
					path: '',
					name: 'Hot Games',
				},
				{
					path: '',
					name: 'New Games',
				},
				{
					path: '',
					name: 'Action Games',
				},
				{
					path: '',
					name: 'Casual Games',
				},
				{
					path: '',
					name: 'Puzzle Games',
				},
				{
					path: '',
					name: 'Racing Games',
				},
				{
					path: '',
					name: 'Sport Games',
				},
				{
					path: '',
					name: 'Match 3 Games',
				},
				{
					path: '',
					name: 'Adventure Games',
				},
				{
					path: '',
					name: 'Shooting Games',
				},
				{
					path: '',
					name: 'Strategy Games',
				},
				{
					path: '',
					name: 'Number Games',
				},
			],
		}
	},

	methods: {},
	computed: {
		...mapGetters(['getDeviceType']),
	},
}
</script>

<style lang="scss" scoped>
.header {
	// margin-bottom: rem(30);s
	box-sizing: content-box;
	.banner {
		height: 100%;
		width: 100%;
		position: relative;
		z-index: 3000;
		display: flex;
		background-color: #eee8aa;
		border-radius: rem(20);
		background: #fff;
		box-shadow: rem(0) rem(5) rem(15) rem(0) rgba(0, 0, 0, 0.4);
		padding: rem(32) rem(20) rem(0);
		.top {
			flex: 1;
			display: flex;
			.logo {
				flex: 1;
				background-image: url('../assets/logo.png');
				background-repeat: no-repeat;
				background-size: contain;
			}
			svg {
				margin-left: rem(10);
				width: rem(40);
				height: rem(40);
			}
		}
		.cate {
			padding-bottom: rem(20);
			z-index: 8889;
			height: 72vh;
			position: absolute;
			width: 100%;
			background: white;
			top: 100%;
			left: 0;
			border-radius: 0 0 rem(20) rem(20);
			overflow: auto;
			.gameCate {
				top: 0;
				display: flex;
				align-items: center;
				font-family: Roboto;
				font-size: rem(26);
				font-style: normal;
				font-weight: 500;
				line-height: rem(60);
				margin-bottom: rem(30);
				padding-left: 18px;
				svg {
					width: rem(40);
					height: rem(40);
					margin-right: rem(12);
				}
				a {
					margin-left: 8px;
					color: #002b51;
				}
			}
			:first-child.gameCate {
				margin-top: rem(20);
			}
			&::after {
				margin-bottom: rem(20);
				display: block;
			}
		}
	}
	.menu {
		border-radius: rem(20) rem(20) 0 0;
		padding-bottom: rem(20);
	}
}
.cover {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2000;
}

.trans {
	aspect-ratio: 1 / 1;
	visibility: hidden;
}
@media screen and (orientation: landscape) {
}
.si {
	svg {
	}
}
</style>