import global from '@/utils/global.js'

const render = {
	namespaced: true,
	state: {
		renderData: [],
		initStart: false,
	},
	mutations: {
		setRenderData(state, data) {
			state.renderData = data
		},
		appendData(state, data) {
			state.renderData = state.renderData.concat(data)
		},
		setInitStart(state, value) {
			state.initStart = value
		},
	},
	actions: {
		setAaa({ commit }, data) {
			commit('setInitStart', data)
		},
		async fetchMoreData({ commit, state }, { page }) {
			if (page === 1) {
				commit('setInitStart', true)
			}
			console.log(`msg from asyncData: { page:${page} }`)
			const data = await fetchDataFromAPI(page) // 模拟API请求
			commit('appendData', data)
			console.log('set ready')
			return Promise.resolve(data) // 确保返回一个 Promise
		},
		async fetchMoreDataServer({ commit, state }, { page }) {
			if (page === 1) {
				commit('setInitStart', true)
			}
			console.log(`msg from serverPrefetch: { page:${page} }`)
			const data = await fetchDataFromAPI(page) // 模拟API请求
			commit('appendData', data)
			console.log('set ready')
			return Promise.resolve(data) // 确保返回一个 Promise
		},
		async getListData({ commit }, req) {
			//这里可以执行我们自定义的接口
			try {
				console.log(req)
				const response = await global.server.getList(req)
				// commit('setRenderData', response.data) // 假设返回的数据格式包含在 response.data 中
				return response
			} catch (error) {
				console.error('Error fetching list data:', error)
				return Promise.reject(error)
			}
		},
	},
}

async function fetchDataFromAPI(page) {
	console.log(`收到dispatch,准备模拟异步请求第${page}页`)
	// 模拟异步数据请求，替换为真实的 API 请求
	return new Promise((resolve) => {
		setTimeout(() => {
			const data = Array.from({ length: 10 }).map((_, index) => ({
				id: (page - 1) * 10 + index + 1,
				title: `News Title ${index + 1}`,
				summary: `Summary of news ${index + 1}`,
				date: new Date().toISOString().split('T')[0],
				author: `Author ${index + 1}`,
				source: `Source ${index + 1}`,
				url: `/news/${(page - 1) * 10 + index + 1}`,
			}))
			console.log(`第${page}页模拟异步数据请完成,输出`)
			resolve(data)
		}, 1000)
	})
}

export default render
